import React from 'react'
import styled from 'styled-components'
import Award from '../../../static/assets/icons/award.svg'
import Social from '../../../static/assets/icons/social.svg'
import Press from '../../../static/assets/icons/press.svg'

const Result = styled.div `
    text-align: center;
    
    h5 {
        font-size: 5em;
        font-weight: 300;
        opacity: 0.74;
    }

    @media (max-width: 768px) {
        transform: scale(0.8);
        transform-origin: 50% 50%;
    }
`

const Results = styled.div `
    display: flex;
    justify-content: space-around;
    
    margin: 5em 0;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`

 const ResultsBar = (props) => {
    

    const Statistics = props.stats.map((stat, i) => {
        const { caption, value, type } = stat;

        let iconImage;
        switch(type) {
            case "award": iconImage = <Award />; break;
            case "social": iconImage = <Social />; break;
            case "press": iconImage = <Press />; break;
            case "stat": iconImage = <h5 className="stat">{value}%</h5>; break;
            default:

            throw new Error(`Invalid color: ${type}`);
        }

        return (
            <Result key={i}>
            {iconImage}
            <h3 className="blog">{caption}</h3>
            </Result>
        )
    });

    return (
        <Results>
            {Statistics}    
        </Results>
    )
}

export default ResultsBar;