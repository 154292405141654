import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import NewsLetterForm from "../components/UI/newsletter-form"
import ShareBanner from "../components/UI/share-banner"
import Footer from "../components/UI/footer"
import AppNavBar from "../components/UI/Navbar-pages"
import CaseImage from "../components/UI/case-image"
import Arrow from "../../static/assets/arrow.svg"
import ResultsBar from "../components/UI/results"

const Case = styled.div`
  margin: 0 10%;
  padding-top: 2em;

  .quotation {
    margin: auto;
    width: 60%;
    color: var(--tuva-blue-color);

    & .name {
      text-align: right;
    }
  }

  .main-image {
    width: 90%;
    margin: auto;
    padding: 3em;
  }

  .figure {
    margin: 0;
    text-align: right;
    font-style: italic;
  }

  .back-button {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: fit-content;
    align-items: center;
    text-decoration: none;
    color: #000;

    :after {
      position: absolute;
      bottom: 1em;
      left: 0;
      right: 0;
      width: 0%;
      content: "";
      color: transparent;
      background: black;
      height: 1px;
      transition: all 0.4s ease-in;
    }

    :hover {
      color: #888;
      transition: all 0.3s;

      ::after {
        width: 100%;
        color: #888;
      }
    }
  }

  @media (max-width: 768px) {
    margin: 0 7%;

    .quotation {
      width: 90%;
    }
    .main-image {
      padding: 1em;
    }
  }
`

const CasePage = ({ data }) => {
  const {
    title,
    summary,
    what,
    brief,
    quote,
    image,
    image_text,
    results,
    client,
    logo,
    stats,
  } = data.markdownRemark.frontmatter

  return (
    <>
      <AppNavBar>
        <CaseImage image={image} title={title} logo={logo} client={client} />
      </AppNavBar>
      <div className="main-section">
        <Case>
          <h1 className="blog">{title}</h1>
          <h5 className="sub-heading">Summary</h5>
          <br />
          <h5>{summary}</h5>
          <br />
          <h5 className="sub-heading">Brief</h5>
          <br />
          <h5>{what}</h5>
          <br />
          {quote && (
            <div className="quotation">
              <h5 className="quote">{quote.text}</h5>
              <br />
              <h5 className="name">{quote.author}</h5>
            </div>
          )}
          <br />
          <h5 className="sub-heading">What We Did</h5>
          <br />
          <h5>{brief}</h5>
          <div className="main-image">
            <GatsbyImage
              image={image.childImageSharp.gatsbyImageData}
              alt={title}
            />
            <p className="figure">{image_text}</p>
          </div>
          <h5 className="sub-heading">Results</h5>
          <br />
          <h5>{results}</h5>
          <ResultsBar stats={stats} />
          <Link to="/our-work" className="back-button">
            <Arrow style={{ paddingRight: "1em" }} />
            <h3 className="blog">BACK TO OUR WORK & CASE STUDIES</h3>
          </Link>
          <NewsLetterForm />
          <ShareBanner />
        </Case>
        <Footer />
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query CaseBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        client
        title
        logo {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [PNG, WEBP, AVIF]
              layout: FULL_WIDTH
            )
          }
        }
        stats {
          value
          type
          caption
        }
        date
        carousel
        image_text
        image {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [PNG, WEBP, AVIF]
              layout: FULL_WIDTH
            )
          }
        }
        hshift
        summary
        brief
        what
        results
        quote {
          author
          text
        }
      }
    }
  }
`

export default CasePage
