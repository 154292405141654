import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import styled from 'styled-components'
import { BgImage } from "gbimage-bridge"

const TitleImage = styled(BgImage) `
    height: 280px;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: -50px;
    overflow: hidden;

  .content-shape {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: flex-end;
      background-color: #fff;
      margin-top: -1px;
      width: 30%;
      height: 100%;
      clip-path: polygon(100% 100%, 0 100%, 30% 0, 100% 0);
      box-shadow: var(--tuva-box-shadow);

    .content-box {
        padding: 25px 0 70px 0;
    }

      .logo {
        margin: auto 2em auto auto;
        width: 45%;
      }
    }

    @media (max-width: 768px){
        
        .content-shape {
            width: 50%;
        }
    }
`

const CaseImage = (props) => {

    return (
        <TitleImage image={props.image.childImageSharp.gatsbyImageData}>
            <div className="content-shape">
                <div className="content-box">
                    <GatsbyImage className="logo"
                    image={props.logo.childImageSharp.gatsbyImageData} 
                    alt={props.client} />
                </div>
            </div>  
          </TitleImage>
    )
}

export default CaseImage